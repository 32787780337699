<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-responsive min-height="350">
          <v-container>
            <transition-group
              tag="v-row"
              name="fade-transition"
            >
              <v-col
                v-for="project in computedProjects2"
                :key="project.img"
                cols="12"
                class="gallery-card"
                md="4"
              >
                <v-hover>
                  <template v-slot="{ hover }">
                    <v-card
                      :img="`/static/x1/${project.img}`"
                      class="white--text overflow-hidden"
                      dark
                      height="300"
                      hover
                      @click="overlay = project.img"
                    >
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                        >
                          <v-icon large>
                            mdi-magnify
                          </v-icon>
                        </v-overlay>
                      </v-fade-transition>
                      <v-slide-y-reverse-transition>
                        <v-footer
                          v-if="hover"
                          absolute
                          class="justify-center"
                          height="75"
                        >
                          <div class="heading">
                            {{ project.name }}
                          </div>
                        </v-footer>
                      </v-slide-y-reverse-transition>
                    </v-card>
                  </template>
                </v-hover>
              </v-col>
            </transition-group>
          </v-container>
        </v-responsive>

        <v-fade-transition mode="out-in">
          <v-overlay
            v-if="overlay"
            fixed
            opacity=".9"
          >
            <v-btn
              color="transparent"
              depressed
              fab
              fixed
              large
              right
              top
              @click="overlay = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>

            <v-img
              :src="`/static/x1/${overlay}`"
              width="800"
              max-width="90vw"
            />
          </v-overlay>
        </v-fade-transition>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="10"
        offset-md="1"
      >
        <base-subheading>
          <h1
            class="mt-5 mb-5"
            style="font-family: 'Skia', sans-serif;"
          >
            Dom jednorodzinny z bala ciosanego - model X1 - 124,5m²
          </h1>
        </base-subheading>
        <p
          class="text-justify"
          style="font-family: 'Montserrat', sans-serif;"
        >
          X1 to przestronny dom z bala 70mm, który spełnia kryteria zabudowy do 70m². Idealny do zamieszkania całorocznego dom drewniany X1 to doskonała propozycja dla osób lubiących nowoczesne rozwiązania komfort i ekologię.
        </p>
        <base-subheading>
          <h3
            class="mt-5 mb-5"
            style="font-family: 'Skia', sans-serif;"
          >
            STAN DEWELOPERSKI
          </h3>
        </base-subheading>
        <p
          class="text-justify"
          style="font-family: 'Montserrat', sans-serif;"
        >
          <ul>
            <li>konstrukcja drewniana z bala 70mm</li>
            <li>podstawowy układ instalacji elektrycznej i wodno - kanalizacyjnej</li>
            <li>okna i drzwi w kolorze antracyt</li>
            <li>ocieplenie - do wyboru: wełna, piana pur, system Bauter</li>
            <li>ogrzewanie na podczerwień</li>
            <li>dach i elewacja wykończone wysokiej jakości blachą na rąbek</li>
          </ul>
        </p>
        <base-subheading>
          <h3
            class="mt-5 mb-5"
            style="font-family: 'Skia', sans-serif;"
          >
            STAN DEWELOPERSKI PODWYŻSZONY
          </h3>
        </base-subheading>
        <p
          class="text-justify"
          style="font-family: 'Montserrat', sans-serif;"
        >
          <ul>
            <li>wykończenie łazienek</li>
            <li>wykończenie kuchni</li>
            <li>rekuperacja</li>
            <li>taras z pergolą</li>
            <li>garaż / wiata garażowa</li>
            <li>aranżacja ogrodu, ogrodzenie, chodniki</li>
            <li>fotowoltaika, magazyn energii</li>
          </ul>
        </p>
        <base-subheading>
          <h3
            class="mt-5 mb-5"
            style="font-family: 'Skia', sans-serif;"
          >
            INFORMACJE DODATKOWE
          </h3>
        </base-subheading>
        <p
          class="text-justify"
          style="font-family: 'Montserrat', sans-serif;"
        >
          Najwyższej jakości świerk skandynawski wykorzystany do budowy domu X1 sprawia, że konstrukcja jest trwała, niezwykle odporna na warunki atmosferyczne i zapewni solidność oraz wytrzymałość przez długie lata. Pochodzące z mocnych i zdrowych świerków skandynawskich bale 70mm są poddawane specjalistycznemu suszeniu (do poziomu 16-18%), struganiu i późniejszej konserwacji. Drewno to ma świetne parametry cieplne. Odpowiednio zabezpieczone łatwo się nagrzewa, a zgromadzone ciepło oddaje bardzo powoli. Dach i część elewacji wykończone są wysokiej jakości blachą na rąbek co nadaje X1 charakteru nowoczesnej Stodoły.
        </p>
        <base-subheading>
          <h3
            class="mt-5 mb-5"
            style="font-family: 'Skia', sans-serif;"
          >
            CENA X1 rozpoczyna się od 597 000 zł
          </h3>
        </base-subheading>
        <p
          style="font-family: 'Skia', sans-serif;"
        >
          Prezentacja modelu X1 w PDF
          <a
            href="/static/prezentacjaX1.pdf"
            target="_blank"
          >
            [zobacz]
          </a>
          <a
            href="/static/prezentacjaX1.pdf"
            download
          >
            [pobierz]
          </a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      category: null,
      overlay: false,
      projects: [
        {
          'name': 'Dom jednorodzinny :: X1',
          'img': 'project6a.jpg',
          'categories': [3]
        },
        {
          'name': 'Powierzchnia całkowita 124,5m²',
          'img': 'project6b.jpg',
          'categories': [3]
        },
        {
          'name': 'Parter: 65,5m² | Piętro: 59m²',
          'img': 'project6e.jpg',
          'categories': [3]
        },
        {
          'name': 'Nowoczesne domy z bala - model X1',
          'img': 'project6d.jpg',
          'categories': [3]
        },
        {
          'name': 'Domy całoroczne bez pozwolenia na budowę',
          'img': 'project6c.jpg',
          'categories': [3]
        },
        {
          'name': 'X1 - komfortowy i ekologiczny',
          'img': 'project6d.jpeg',
          'categories': [3]
        }
      ]
    }),

    computed: {
      computedProjects2 () {
        return !this.category
          ? this.projects
          : this.projects.filter(p => p.categories.includes(this.category))
      }
    }
  }
</script>

<style scoped>
  .gallery-card {
    transform-origin: center center 0;
    transition: .3s cubic-bezier(.25,.8,.50,1);
  }

  .fade-transition-leave,
  .fade-transition-leave-active,
  .fade-transition-leave-to {
    display: none;
  }
</style>
